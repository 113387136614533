<script setup lang="ts">
import OnboardingStickyButtonWrapper from "~/components/onboarding/OnboardingStickyButtonWrapper.vue";
import AppInput from "~/components/app/AppInput.vue";
import { useVoucherStore } from "~/stores/useVoucherStore";
import type { Voucher } from "~/types/voucher";
import OnboardingDiscountBanner from "~/components/onboarding/OnboardingDiscountBanner.vue";

interface Emits {
  (e: "next", value: { voucher: Voucher; voucherCode: string }): void;
}

const emit = defineEmits<Emits>();

const voucherStore = useVoucherStore();
const { getValidVoucherCodeDetails } = voucherStore;
const { t } = useNuxtApp().$i18n;
const { addActionEvent } = useTrackingStore();
const code = ref<string>("");
const voucher = ref<Voucher | undefined>();
const error = ref<string | undefined>();
const isLoading = ref<boolean>(false);

async function next() {
  error.value = undefined;
  isLoading.value = true;

  if (!code.value) {
    return;
  }

  try {
    const voucherDetails = await getValidVoucherCodeDetails(code.value);
    voucher.value = voucherDetails;

    emit("next", {
      voucher: voucherDetails,
      voucherCode: code.value,
    });
  } catch (_) {
    addActionEvent("web_funnels.coupon_page.error", { coupon_code: code.value });

    error.value = t("app.prosignup.pro.error.coupon_invalid");
    isLoading.value = false;
  }
}
</script>

<template>
  <OnboardingStickyButtonWrapper
    :button-disabled="!code || isLoading"
    :button-text="$t('app.propayment.step2.button.entercoupon')"
    :is-loading="isLoading"
    show-login-hint
    @button-click="next"
  >
    <h1 class="text-center">
      {{ $t("app.propayment.step2.label.coupon") }}
    </h1>

    <OnboardingDiscountBanner
      v-if="useDiscountStore().code === 'tkfit'"
      :discount-override="60"
      class="mx-auto my-5 lg:w-4/12"
    />

    <OnboardingDiscountBanner
      v-if="useDiscountStore().code === 'kaufland'"
      :discount-override="50"
      class="mx-auto my-5 lg:w-4/12"
    />

    <AppInput
      v-model="code"
      :error="error"
      :placeholder="$t('app.propayment.step2.label.inputcoupon')"
      :input-class="{
        '!border-yz-red-danger focus:!border-yz-red-danger focus:!ring-yz-red-danger': error && !voucher,
        '!border-delight-green-600 focus:!border-delight-green-600 focus:!ring-delight-green-600': voucher,
      }"
      :disabled="isLoading"
      class="m-auto max-w-[600px]"
      field="couponCode"
      @submit="next"
    />
  </OnboardingStickyButtonWrapper>
</template>
